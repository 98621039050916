import React from "react";
import TwitterFeed from '../../_molecules/twitter-feed/index';
import { FeedWrapper, Root } from "./feeds-styles";
import ContentFeed from '../../_molecules/content-feed/index';

const Feeds = ({ tweets, user, resources, videos }) => {

    return (
        <Root>
            <FeedWrapper>
                <ContentFeed
                    title="recommended reading"
                    icon="reading"
                    items={resources.map(r => ({
                        title: r.title,
                        link: r.url
                    }))}
                    CTA="View more bookmarked resources..."
                    CTAlink="/resources"
                />
            </FeedWrapper>
            {/* <FeedWrapper>
                <TwitterFeed tweets={tweets} user={user} />
            </FeedWrapper> */}
            { videos &&
            <FeedWrapper>
                <ContentFeed
                    title="recommended viewing"
                    icon="video"
                    items={videos.map(r => ({
                        title: r.title,
                        link: r.url
                    }))}
                    CTA="View more bookmarked videos..."
                    CTAlink="/videos"
                />
            </FeedWrapper>
            }
        </Root>
  )
};

export default Feeds;